export default {
	resetCurrent (state, stepNumber) {
		/* eslint-disable no-fallthrough */
		// Fallthrough (no break) is on purpose here
		switch(stepNumber) { 
			case 1: state.currentModel = null;
			case 2: state.currentLayer = null;
			case 3: state.currentPatterns = null;
					state.currentPattern = null;
					state.currentCodes = null;
		}
		/* eslint-enable no-fallthrough */
	},
	setCurrentModel (state, payload) {
		state.currentPatterns = null;
		state.currentPattern = null;
		state.currentCodes = null;
		state.currentModel = {
			sku: payload.sku,
			brand: payload.brand,
			name: payload.name,
			layers: payload.layers,
			mockup: payload.mockup,
			defaultMockup: payload.defaultMockup,
		};
		state.showInfobox = true;
	},
	setDevices (state, payload) {
		state.models = [];
		payload.forEach(device => {
			state.models.push({
				'id': device.id,
				'sku': device.sku,
				'brand': device.brand,
				'name': device.name,
				'mockup': device.mockup,
				'defaultMockup': device.defaultMockup,
				'layers': device.layers ? device.layers : [],
			});
		});
	},
	setLayers (state, payload) {
		state.layers = [];
		payload.forEach(layer => {
			state.layers.push({
				'id': layer.id,
				'code': layer.code,
				'description': layer.description,
				'postfix': layer.postfix,
				'order': layer.order,
				'product_link': layer.product_link,
			});
		});
	},
	setCurrentLayer (state, payload) {
		state.currentLayer = Object.assign({}, payload);
	},
	setPatterns (state, payload) {
		state.patterns = [];
		state.categories = [];
		payload.forEach(pattern => {
			state.patterns.push({
				'id': pattern.id,
				'code': pattern.code,
				'name': pattern.name,
				'description': pattern.description,
				'file': pattern.file,
				'video': pattern.video,
				'category': pattern.category,
				'featured': pattern.featured,
				'layers': [...pattern.layers],
			});
			if ( !state.categories.includes(pattern.category) ) {
				state.categories.push(pattern.category);
			}
		});
	},
	setPrices (state, payload) {
		state.prices = [];
		payload.forEach(price => {
			state.prices.push({
				'price': price.price,
				'currency': price.currency,
				'layer_id': price.layer_id,
				'layer_code': price.layer_code,
				'pattern_id': price.pattern_id,
				'pattern_code': price.pattern_code,
				'x': price.x,
				'y': price.y,
			});
		});
	},
	setMockup (state, payload) {
		state.mockup = [];
		Object.keys(payload).forEach(key => {
			if (key === 'default') {
				state.defaultMockup = payload[key]
			} else if (key === 'baseImages') {
				const baseImages = [];
				for (let i = 0; i < payload[key].length; i++) {
					baseImages.push({
						code: payload[key][i].code,
						url: payload[key][i].path,
						z: 0,
						default: payload[key][i].default
					});
				}
				state.mockup.push({
					code: key,
					bases: baseImages,
				});
			} else if (key === 'forcedBaseImages') {
				const forcedBaseImages = [];
				for (let i = 0; i < payload[key].length; i++) {
					forcedBaseImages.push({
						code: payload[key][i].code,
						url: payload[key][i].path,
						z: 0,
						default: payload[key][i].default
					});
				}
				state.mockup.push({
					code: key,
					bases: forcedBaseImages,
				});
			} else {
				state.mockup.push({
					code: key,
					url: payload[key].url,
					z: payload[key].z,
					price_x: payload[key].price_x,
					price_y: payload[key].price_y,
				});
			}
		});
	},
	setCurrentPattern (state, payload) {
		state.currentPatterns = { [payload.layer]: payload.background }
		state.currentPattern = payload.name;
		state.currentCodes = { [payload.layer]: payload.code }
	},
	setSettings (state, payload) {
		const tmpSettings = {};
		payload.forEach(setting => {
			tmpSettings[setting.variable] = setting.value;
		});
		state.settings = { ...tmpSettings };
	},
	toggleInfoBox (state, payload) {
		state.showInfobox = payload;
	}
}