<template>
	<div :id="`${price.layer_code}${price.pattern_code}`" :class="`apgo-pricetag${ hidden ? ' closed' : ''}`" :style="`left: ${left}px; top: ${top}px`" @click="toggle">
		{{ price.price }}&nbsp;<small>{{ price.currency }}</small>
	</div>
</template>

<script>
export default {
	name: 'PriceTag',
	props: [ 'price' ],
	data: () => ({
		hidden: true,
		left: 0,
		top: 0,
	}),
	mounted() {
		const bgElement = document.getElementById(`layer-${this.price.layer_code}`);
		const style = getComputedStyle( bgElement );
		let src = style.maskImage || style.webkitMaskImage;
		const preloaderImg = document.createElement("img");
		preloaderImg.src = src.replace('url("', '').replace('")', '');
		preloaderImg.addEventListener('load', () => {
			const scale = bgElement.offsetWidth / preloaderImg.naturalWidth;
			this.left = this.getLeft(scale);
			this.top = this.getTop(scale);
			this.hidden = false;
		});
	},
	methods: {
		toggle() {
			this.hidden = ! this.hidden;
		},
		getLeft(scale) {
			let x = (this.$store.state.mockup.filter(item => item.code == this.price.layer_code)[0].price_x || 0);
			x *= scale;
			return Math.round(x);
		},
		getTop(scale) {
			let y = (this.$store.state.mockup.filter(item => item.code == this.price.layer_code)[0].price_y || 0);
			y *= scale;
			return Math.round(y);
		}
	}
}
</script>

<style lang="scss" scoped>
.apgo-pricetag {
	cursor: pointer;
	position: absolute;
	z-index: 1000;
	padding: 5px;
	box-sizing: border-box;
	border-radius: 0 7px 7px 7px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, .1) inset;
	font-size: 1em;
	line-height: 1em;
	color: white;
	transition: all .5s;
	background: linear-gradient(0, #E81485, #EC6767);
	overflow: hidden;

	&.closed {
		border-radius: 50%;
		width: 15px;
		height: 15px;
		color: transparent;
	}
}
</style>