<template>
<div class="apgo-devices-list" v-loading="allModelsCount === 0" element-loading-text="Poczekaj chwilkę, ładuję listę modeli…">
	<div class="apgo-devices-search">
		<el-form ref="form" :model="form" @submit.native.prevent="blur()">
			<el-form-item label="Wpisz model aby wyszukać">
				<el-input placeholder="Wyszukaj model urządzenia…" v-model="form.search" @keyup.enter="blur()" style="margin-bottom: 0;">
					<el-button type="primary" slot="append" @click.native.prevent="blur()"><i class="icofont-search-2"></i></el-button>
				</el-input>
			</el-form-item>
		</el-form>
		<p class="apgo-search-help" v-html="search_help"></p>
	</div>
	<div class="apgo-models-list">
		<el-collapse accordion>
			<el-collapse-item v-for="(brand, index) in brandsWithModels" :key="index" :name="index+1" >
				<template slot="title"><div class="apgo-brand-title">{{ brand.name }}</div></template>
				<template v-for="model in brand.models">
					<Device
						:key="model.sku"
						:sku="model.sku"
						:brand="model.brand"
						:name="model.name"
						:mockup="model.mockup"
						:defaultMockup="model.defaultMockup"
						:layers="model.layers"
						@chosen="$emit('chosen')"
					></Device>
				</template>
			</el-collapse-item>
		</el-collapse>
		<div class="apgo-empty-results" v-if="brands.length === 0">
			<span>Brak urządzeń o takiej nazwie</span>
		</div>
	</div>
</div>
</template>

<script>
import Device from '@/components/Device.vue';

export default {
	name: 'DevicesList',
	components: { Device },
	data: () => ({
		form: {
			search: ''
		}
	}),
	computed: {
		allModelsCount() {
			return this.$store.state.models.length;
		},
		brands() {
			const brands = [];
			const models = this.$store.state.models;

			models.forEach(model => {
				if (this.form.search) {
					// Select only those models that match search query
					(
						model.brand.toLowerCase().includes(this.form.search.toLowerCase()) ||
						model.name.toLowerCase().includes(this.form.search.toLowerCase())
					)
					&& !brands.includes(model.brand)
					&& brands.push(model.brand);
				} else {
					!brands.includes(model.brand) && brands.push(model.brand);
				}
			});

			brands.sort();
			return brands;
		},
		brandsWithModels() {
			const models = this.$store.state.models;
			let brandsWithModels = []
			this.brands.forEach(brand => {
				const filtered = models.filter(model => {
					if (this.form.search) {
						return (
							model.brand.toLowerCase().includes(this.form.search.toLowerCase()) ||
							model.name.toLowerCase().includes(this.form.search.toLowerCase())
						) && model.brand === brand;
					} else {
						return model.brand === brand;
					}
				});
				if (filtered.length > 0) {
					brandsWithModels.push({
						models: filtered,
						name: brand
					});
				}
			});
			return brandsWithModels;
		},
		email() { return this.$store.state.settings.email; },
		search_help() {
			return String(this.$store.state.settings.search_help)
				.replace(
					'[email]',
					`<a href="mailto:${this.$store.state.settings.email}">${this.$store.state.settings.email}</a>`
				);
		}
	},
	methods: {
		blur(e) {
			e.target && e.target.blur();
			e.currentTarget && e.currentTarget.blur();
		}
	}
}
</script>

<style lang="scss" scoped>
.apgo-devices-search {
	padding: 25px;
	display: flex;
	justify-content: stretch;
	flex-direction: column;
	text-align: center;
	background: white;
}

.apgo-search-help {
	font-size: .8em;
}
.apgo-empty-results {
	display: flex;
	height: 3em;
	align-items: top;
	justify-content: center;
	color: #E81485;
}

.apgo-brand-title {
	display: inline-block;
	padding-left: 25px;
	color: #E81485;
	font-weight: bold;
	animation: blinkingColor 2s infinite;
}

@keyframes blinkingColor {
	0% {
		color: #E81485;
	}
	50% {
		color: #333;
	}
	100% {
		color: #E81485;
	}
}
</style>