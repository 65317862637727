<template>
	<div class="apgo-pattern-tile" :style="`background-image: url('${image}')`" @click="selectPattern()">
		<div class="apgo-tile-name" :title="name">{{ name }}</div>
		<div class="apgo-tile-info">
			<div v-if="featured" class="apgo-tile-featured">{{ featuredText }}</div>
			<div v-if="video" class="apgo-tile-video" :title="youtubeText" @click.stop="seeVideo"><i class="icofont-youtube-play icofont-lg"></i></div>
			<el-popover v-if="description" @click.stop="1" class="apgo-tile-description"
				placement="bottom"
				:title="name"
				width="200"
				trigger="click"
				:content="description">
				<div slot="reference" class="apgo-tile-description-button" @click.stop><i class="icofont-info-circle icofont-lg"></i></div>
			</el-popover>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PatternTile',
	props: {
		name: { type: String, required: true },
		description: { type: String, required: false },
		code: { type: String, required: true },
		image: { type: String, required: true },
		layer: { type: String, required: true },
		video: { type: String, required: false },
		featured: { type: Boolean, required: true },
	},
	computed: {
		featuredText() { return this.$store.state.settings.featured_text || 'Featured'; },
		youtubeText() { return this.$store.state.settings.youtube_text || 'See on YouTube'; }
	},
	methods: {
		selectPattern() {
			this.$emit('chosen');
			this.$store.commit('setCurrentPattern', {
				background: this.image,
				code: this.code,
				layer: this.layer,
				category: this.category,
				name: this.name
			});
		},
		seeVideo() {
			window.open(`https://www.youtube.com/watch?v=${this.video}`); // TODO: wyświetlaj w lightbox tak jak manual
		}
	}
}
</script>

<style lang="scss" scoped>
.apgo-pattern-tile {
	display: inline-block;
	width: 120px;
	height: 200px;
	overflow: hidden;
	border-radius: 7px;
	margin: 15px 0 10px 25px;
	box-shadow: 0 0 5px rgba(0,0,0,.5);
	transition: all .5s;
	cursor: pointer;
	position: relative;
}
.apgo-pattern-tile:hover {
	box-shadow: 0 0 5px #E81485;
}
.apgo-tile-name {
	background: white;
	text-align: center;
	padding: 5px;
	overflow: hidden;
	white-space: nowrap;
}
.apgo-tile-info {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
	display: flex;
	flex-wrap: wrap;
}
.apgo-tile-featured {
	background: #E81485;
	color: white;
	text-align: center;
	padding: 5px 10px;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
}
.apgo-tile-video {
	color: #f00;
	background: white;
	text-align: center;
	height: 35px;
	overflow: hidden;
	white-space: nowrap;
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 1 auto;
}
.apgo-tile-description {
	color: #333;
	background: white;
	width: 50%;
	flex: 1 1 auto;
}
.apgo-tile-description-button {
	color: #333;
	background: white;
	text-align: center;
	height: 35px;
	overflow: hidden;
	white-space: nowrap;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>