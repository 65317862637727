<template>
	<div class="apgo-patterns">
		<template v-for="(pattern, index) in getFeatured">
			<PatternTile v-if="pattern.layers.includes(layer)"
				:key="index"
				:name="pattern.name"
				:description="pattern.description"
				:code="pattern.code"
				:video="pattern.video"
				:image="pattern.file"
				:featured="pattern.featured ? true : false"
				:layer="layer"
				@chosen="$emit('chosen')"
			></PatternTile>
		</template>

		<el-collapse accordion>
			<el-collapse-item v-for="(category, index) in getPatterns" :key="index" :name="index+1" class="apgo-pattern-category">
				<template slot="title"><div class="apgo-pattern-title">{{ category.name }}</div></template>
				<template v-for="(pattern, index) in category.patterns">
					<PatternTile v-if="pattern.layers.includes(layer)"
						:key="index"
						:name="pattern.name"
						:description="pattern.description"
						:code="pattern.code"
						:video="pattern.video"
						:image="pattern.file"
						:featured="pattern.featured ? true : false"
						:layer="layer"
						@chosen="$emit('chosen')"
					></PatternTile>
				</template>
			</el-collapse-item>
		</el-collapse>

		<template v-for="(pattern, index) in getPatternsWithoutCategory">
			<PatternTile v-if="pattern.layers.includes(layer)"
				:key="index"
				:name="pattern.name"
				:description="pattern.description"
				:code="pattern.code"
				:video="pattern.video"
				:image="pattern.file"
				:featured="pattern.featured ? true : false"
				:layer="layer"
				@chosen="$emit('chosen')"
			></PatternTile>
		</template>
	</div>
</template>

<script>
import PatternTile from '@/components/PatternTile.vue';

export default {
	name: 'Patterns',
	components: { PatternTile },
	props: {
		layer: {type: String, required: true }
	},
	computed: {
		getPatterns() {
			const categories = this.$store.state.categories;
			const patterns = this.$store.state.patterns;

			let categoriesWithPatterns = []
			let filtered;
			categories.forEach(category => {
				filtered = patterns.filter(pattern => {
						return pattern.category === category && pattern.layers.includes(this.layer);
				});
				if (filtered.length > 0) {
					categoriesWithPatterns.push({
						patterns: filtered,
						name: category
					});
				}
			});
			return categoriesWithPatterns;
		},
		getPatternsWithoutCategory() {
			const patterns = this.$store.state.patterns;
			return patterns.filter(pattern => {
				return !pattern.category && pattern.layers.includes(this.layer);
			});
		},
		getFeatured() {
			return this.$store.state.patterns.filter(pattern => pattern.featured);
		}
	}
}
</script>

<style lang="scss" scoped>
.apgo-pattern-category > div {
	background: white;
}
.apgo-pattern-title {
	display: inline-block;
	padding-left: 3em;
}
</style>