<template>
	<div class="apgo-device-mockup">
			<div class="apgo-mockup">
				<img v-if="getMockupBaseImage('forced')" class="forced-base" :style="`z-index: ${forcedBaseZ}`" :src="forcedBaseUrl">
				<img v-if="getMockupBaseImage()" class="base" :style="`z-index: ${baseZ}`" :src="baseUrl">
				<template v-for="layer in layers">
					<div :key="layer.code" :id="`layer-${layer.code}`" class="layer" :style="`mask-image: url('${getMaskUrl(layer.code)}'); z-index: ${layer.z}; background-image: url('${$store.state.currentPatterns && $store.state.currentPatterns[layer.code]}')`"></div>
				</template>
				<template v-for="layer in effects">
					<img :key="layer.code" :ref="layer.code" class="effects" :style="`z-index: ${layer.z}`" :src="layer.url">
				</template>
				<template v-for="price in prices">
					<PriceTag :price="price" :key="`${price.layer_code}${price.pattern_code}`" />
				</template>
			</div>
	</div>
</template>

<script>
import PriceTag from '@/components/PriceTag.vue';

export default {
	name: 'DeviceMockup',
	components: { PriceTag },
	data: () => ({
		baseUrl: '',
		baseZ: 0,
		forcedBaseUrl: '',
		forcedBaseZ: 0,
		forcedBase: false,
	}),
	computed: {
		base() {
			return this.$store.getters.getMockupBaseImage;
		},
		effects() {
			return this.$store.state.mockup && this.$store.state.mockup
				.filter(item => item.code.substr(0, 11) === 'top_effects');
		},
		layers() {
			return this.$store.state.mockup && this.$store.state.mockup
				.filter(item => item.code.substr(0, 11) !== 'top_effects' && item.code !== 'base' && item.code !== 'baseImages');
		},
		prices() {
			const groups = [];
			this.$store.state.currentCodes && Object.keys(this.$store.state.currentCodes).forEach(key => {
				groups.push(key + this.$store.state.currentCodes[key]);
			});
			if (groups.length > 0) {
				return this.$store.state.prices && this.$store.state.prices
					.filter(item => groups.includes(item.layer_code + item.pattern_code));
			}
			return null;
		}
	},
	methods: {
		getMaskUrl(layerCode) {
			let url = this.$store.state.mockup.filter(layer => layer.code === layerCode)[0];
			return url.url || '';
		},
		isLayer(code) {
			return code == 'base' || code.substr(0, 11) === 'top_effects' ? false : true
		},
		getMockupBaseImage(type = '') {
			let baseImages;
			if (this.$store.state.mockup && this.$store.state.mockup.length) {
				for (let i = 0; i < this.$store.state.mockup.length; i++) {
					baseImages = type === 'forced' ? 'forcedBaseImages' : 'baseImages';

					if (this.$store.state.mockup[i].code === baseImages) {
						let defaultIndex = -1;
						for (let k = 0; k < this.$store.state.mockup[i].bases.length; k++) {
							if (this.$store.state.mockup[i].bases[k].code === this.$store.state.currentLayer.code) {
								if (type === 'forced') {
									this.forcedBaseUrl = this.$store.state.mockup[i].bases[k].url;
									this.forcedBaseZ = 0;
									this.forcedBase = true;
								} else {
									this.baseUrl = this.$store.state.mockup[i].bases[k].url;
									this.baseZ = this.forcedBase ? 1 : 0;
								}
								return true;
							}
							if (this.$store.state.mockup[i].bases[k].default) {
								defaultIndex = k;
							}
						}
						if (type === 'forced') {
							this.forcedBaseUrl = this.$store.state.mockup[i].bases[defaultIndex].url;
							this.forcedBaseZ = 0;
							this.forcedBase = true;
						} else {
							this.baseUrl = this.$store.state.mockup[i].bases[defaultIndex].url;
							this.baseZ = this.forcedBase ? 1 : 0;
						}
						return true
					}
					if (this.$store.state.mockup[i].code === 'base' && type !== 'forced') {
						this.baseUrl = this.$store.state.mockup[i].url;
						this.baseZ = this.$store.state.mockup[i].z;
						return true
					}
				}
			}
			return false;
		}
	}
}
</script>

<style lang="scss" scoped>
.apgo-device-mockup {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	z-index: 1;
	padding-top: 75px;
	box-sizing: border-box;
}
.apgo-mockup {
	position: relative;
	height: 100vh;
	width: min(100vw, 100vh);
}

.apgo-mockup > .base,
.apgo-mockup > .forced-base {
	display: block;
	position: absolute;
	z-index: 0;
	max-height: 100vh;
	max-width: 100%;
}

.apgo-mockup > .effects {
	position: absolute;
	top: 0;
	left: 0;
	max-height: 100vh;
	max-width: 100%;
	z-index: 33;
}

.apgo-mockup > .layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: 100%;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
  -webkit-mask-size: contain;
	mask-size: contain;
	background-size: contain;
}
</style>