<template>
	<div class="apgo-layers-list">
		<div v-for="(layer, index) in getLayers" :key="index" :tab-index="index+1" class="apgo-layer" @click="handleClick(layer)">
			<div class="apgo-layer-title">{{ layer.description }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LayersList',
	computed: {
		getLayers() {
			const deviceLayers = this.$store.state.currentModel.layers;
			return this.$store.state.layers.filter(layer => {
				return deviceLayers && deviceLayers.includes(layer.code);
			});
		},
	},
	methods: {
		handleClick(layer) {
			this.$store.commit('setCurrentLayer', layer);
			this.$emit('chosen');
		}
	}
}
</script>

<style lang="scss">
.apgo-layer-title {
	display: inline-block;
	padding-left: 1.5em;
	color: #E81485;
	font-weight: bold;
	animation: blinkingColor 2s infinite;
}
.apgo-layer {
	background: whitesmoke;
	padding: 15px 0;
	border-bottom: 1px solid #eee;
	cursor: pointer;
	transition: all .5s;
}
.apgo-layer:hover,
.apgo-layer:focus,
.apgo-layer:active {
	background: white;
	padding: 15px 0 15px 5px;
}

@keyframes blinkingColor {
	0% {
		color: #E81485;
	}
	50% {
		color: #333;
	}
	100% {
		color: #E81485;
	}
}
</style>