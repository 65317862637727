<template>
	<div class="apgo-device" @click="selectThisDevice">
		<span class="name">{{ name }}</span>
	</div>
</template>

<script>
export default {
	name: 'Device',
	props: {
		sku: { type: String, required: true },
		brand: { type: String, required: true },
		name: { type: String, required: true },
		screen: { type: String, required: false },
		mockup: { type: String, required: true },
		defaultMockup: { type: Boolean, required: true },
		layers: { type: Array, required: true },
	},
	methods: {
		selectThisDevice() {
			this.$store.commit('setCurrentModel', {
				sku: this.sku,
				brand: this.brand,
				name: this.name,
				layers: this.layers,
				mockup: this.mockup,
				defaultMockup: this.defaultMockup,
			});
			this.$emit('chosen');
			this.fetchMockup();

			const mainContainer = document.getElementById('apgoapp');
			window.scroll({
				top: mainContainer.offsetTop, 
				left: 0, 
				behavior: 'smooth'
			});
		},
		fetchMockup() {
			fetch(`${window.apgoapi}/mockup/${this.mockup ? this.mockup : 'default'}`)
				.then(response => response.json())
				.then(response => this.$store.commit('setMockup', response));
		}
	}
}
</script>

<style lang="scss" scoped>
.apgo-device {
	display: flex;
	height: 3em;
	align-items: center;
	justify-content: center;
	border-top: 1px solid whitesmoke;
	transition: all .5s;
	cursor: pointer;

	&:hover {
		background: white;
	}
}
</style>