import Vue from 'vue'
import store from './store'
import { Alert, Button, Collapse, CollapseItem, Form, FormItem, Input, Loading, Popover } from 'element-ui';
import App from './App.vue'

if (document.getElementById('apgoapp')) {
	Vue.config.productionTip = false

	Vue.use(Alert);
	Vue.use(Button);
	Vue.use(Collapse);
	Vue.use(CollapseItem);
	Vue.use(Form);
	Vue.use(FormItem);
	Vue.use(Input);
	Vue.use(Loading);
	Vue.use(Popover);

	window.apgoapi = process.env.VUE_APP_APGO_API;

	new Vue({
		store,
		render: h => h(App)
	}).$mount('#apgoapp')
}