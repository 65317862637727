export default {
	getFormattedLayersAndPatternNames: state => {
		const patternCodes = state.currentCodes;
		const layerCodes = Object.keys(patternCodes);
		let names = [];
		layerCodes.forEach(layerCode => {
			const pattern = state.patterns.filter(pattern => {
				return pattern.code === patternCodes[layerCode];
			});
			const layer = state.layers.filter(layer => {
				return layer.code === layerCode;
			});
			names[layerCode] = pattern && pattern[0] && pattern[0].name;
			names[layerCode] += ' ';
			names[layerCode] += layer && layer[0] && (layer[0].postfix || ' - ' + layer[0].description);
		});
		return names;
	},
	getSKUCodes: state => {
		const patternCodes = state.currentCodes;
		const layerCodes = Object.keys(patternCodes);
		const mainSKU = state.currentModel.sku || '…';
		let sku = [];
		layerCodes.forEach(layerCode => {
			sku.push({
				'pattern': patternCodes[layerCode],
				'main': mainSKU,
				'layer': layerCode,
			});
		});
		return sku;
	},
	getFormattedSKUCodes: state => {
		const patternCodes = state.currentCodes;
		const layerCodes = Object.keys(patternCodes);
		const mainSKU = state.currentModel.sku || '…';
		let sku = '';
		layerCodes.forEach(layerCode => {
			//layers += `<li>${patternCodes[layerCode]}-${mainSKU}${layerCode}</li>`;
			sku += `<li>${patternCodes[layerCode]}-${mainSKU}${layerCode}</li>`;
		});
		return `<ul>${sku}</ul>`;
	},
	getFormattedSKUCode: state => {
		const patternCodes = state.currentCodes;
		const layerCodes = Object.keys(patternCodes);
		const mainSKU = state.currentModel.sku || '…';
		return `${patternCodes[layerCodes[0]]}-${mainSKU}${layerCodes[0]}`;
	},
	getFullLayersList: state => {
		const patternCodes = state.currentCodes;
		const layerCodes = Object.keys(patternCodes);
		const mainSKU = state.currentModel.sku || '…';
		const linkLabel = state.settings.product_link_label;

		let list = '';
		layerCodes.forEach(layerCode => {
			const pattern = state.patterns.filter(pattern => {
				return pattern.code === patternCodes[layerCode];
			});
			const layer = state.layers.filter(layer => {
				return layer.code === layerCode;
			});
			list += '<li>';
			list += `${layer && layer[0] && (layer[0].postfix || layer[0].description)} - `;
			list += `${pattern && pattern[0] && pattern[0].category} - `;
			list += `${pattern && pattern[0] && pattern[0].name} - `;
			list += layer && layer[0] && layer[0].product_link ? `${state.settings.product_link_prefix} <a href="${layer[0].product_link}" target="_blank">${linkLabel}</a>. ` : '';
			list += state.settings.product_link_sku_prefix;
			list += ` ${patternCodes[layerCode]}-${mainSKU}${layerCode}`;
			list += '</li>';
		});
		return `<ul>${list}</ul>`;
	},
}
