<template>
	<div :class="isStandalone ? 'apgo-home apgo-standalone' : 'apgo-home'">
		<div class="apgo-steps-block">
			<div class="apgo-steps">
				<div :class="`hamburger ${showStepsOnMobile ? ' close' : ''}`" @click="showStepsOnMobile = !showStepsOnMobile"><span></span></div>
				<div :class="`first-step ${getStepClass(1)}`" @click="resetToStep(1)">
					<i class="icofont-mobile-phone icofont-lg apgo-nav-icon"></i>
					<div class="step-label">{{ currentModelName }}</div>
				</div>
				<div :class="getStepClass(2)" @click="resetToStep(2)">
					<i class="icofont-paint icofont-lg apgo-nav-icon"></i>
					<div class="step-label">
						{{ configureLabel }}
						<div class="current-selection" v-if="$store.state.currentLayer && $store.state.currentLayer.description">{{ $store.state.currentLayer.description }} (zmień)</div>
					</div>
				</div>
				<div :class="getStepClass(3)" @click="resetToStep(3)">
					<i class="icofont-paint icofont-lg apgo-nav-icon"></i>
					<div class="step-label">
						Skonfiguruj
						<div class="current-selection" v-if="$store.state.currentPattern">{{ $store.state.currentPattern }} (zmień)</div>
					</div>
				</div>
				<div :class="getStepClass(4)">
					<i class="icofont-paint icofont-lg apgo-nav-icon"></i>
					<div class="step-label">Wizualizacja</div>
				</div>
				<a v-if="4 == step" :href="productLink()" terget="_blank" class="apgo-submit">
					<i class="icofont-cart icofont-lg apgo-nav-icon"></i>
					<div class="step-label"> {{ submitLabel }}</div>
				</a>
			</div>
			<div v-if="1 == step">
				<DevicesList @chosen="goToNextStep()"></DevicesList>
			</div>
			<div v-if="2 == step">
				<LayersList @chosen="goToNextStep()"></LayersList>
			</div>
			<div v-if="3 == step">
				<div class="apgo-featured">
					<div v-for="(pattern, index) in featuredPatterns" :key="index">
						<PatternTile v-if="pattern.layers.includes($store.state.currentLayer)"
							:key="index"
							:name="pattern.name"
							:code="pattern.code"
							:video="pattern.video"
							:image="pattern.file"
							:layer="$store.state.currentLayer.code"
							:allowedLayers="pattern.layers"
							@chosen="goToNextStep()"
						></PatternTile>
					</div>
				</div>
				<Patterns :layer="$store.state.currentLayer.code" @chosen="goToNextStep()" />
			</div>
			<div v-if="4 == step">
				<div v-if="showInfobox" class="apgo-default-mockup-info">
					<div class="apgo-close" @click="closeInfoBox">&times;</div>
					{{ $store.state.settings.default_info }}
				</div>
				<DeviceMockup id="apgo-device-mockup" />
			</div>
		</div>

		<div v-if="manualVisibility" class="apgo-lightbox" @click="showManual(false)">
			<div class="apgo-lightbox-inner">
				<div class="apgo-video-box">
					<iframe width="100%" height="100%" :src="`https://www.youtube-nocookie.com/embed/${videoId}`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DeviceMockup from '@/components/DeviceMockup.vue'
import DevicesList from '@/components/DevicesList.vue';
import LayersList from '@/components/LayersList.vue';
import PatternTile from '@/components/PatternTile.vue';
import Patterns from '@/components/Patterns.vue';

export default {
name: 'Main',
components: { 
	DeviceMockup,
	DevicesList,
	LayersList,
	PatternTile,
	Patterns,
},
data: () => ({
	step: 1,
	manualVisibility: false,
	orderVisibility: false,
	arrowDirection: 0,
	showStepsOnMobile: false,
}),
computed: {
	showInfobox() { return this.$store.state.currentModel && this.$store.state.currentModel.mockup && this.$store.state.currentModel.defaultMockup && this.$store.state.showInfobox; },
	videoId() { return this.$store.state.settings.youtube_manual_video_id; },
	isStandalone() { return this.$store.state.settings.is_standalone; },
	currentModelName() {
		return this.$store.state.currentModel && this.$store.state.currentModel.name || this.$store.state.settings.model_nav_label;
	},
	configureLabel() { return this.$store.state.settings.configure_nav_label ?? '…'; },
	featuredPatterns() {
		return this.$store.state.patterns.filter(pattern => pattern.featured);
	},
	submitLabel() { return this.$store.state.settings.summary_text ?? '…'; }
},
methods: {
	showManual(show = true) {
		if (this.$store.state.settings.youtube_manual_link) {
			window.open(this.$store.state.settings.youtube_manual_link);
		} else {
			this.manualVisibility = show;
		}
	},
	getStepClass(i) {
		let className = 'apgo-button';
		className += i === this.step ? ' active' : '';
		className += this.showStepsOnMobile ? ' show' : '';
		if (
			this.$store.state.currentModel && i === 1 ||
			this.$store.state.currentLayer && i === 2 ||
			this.$store.state.currentPatterns && i === 3
		) {
			className += ' chosen';
		}
		return className;
	},
	closeInfoBox() {
		this.$store.commit('toggleInfoBox', false);
	},
	productLink() {
		if (this.$store.state.settings.is_standalone) {
			return `https://apgo.eu/produkty?s=${this.$store.getters.getFormattedSKUCode}`;
		} else {
			return `https://apgo.eu/szukaj.html/szukaj=${this.$store.getters.getFormattedSKUCode}`;
		}
	},
	getOrderSummary() {
		let template = '';
		if (this.$store.state.currentModel && this.$store.state.currentPatterns) {
			const email = this.$store.state.settings.email;
			const link = this.$store.state.settings.shop_link;
			const model = `${this.$store.state.currentModel.name} (${this.$store.state.currentModel.brand})`;
			const layers = this.$store.getters.getFormattedLayersAndPatternNames;
			const sku = this.$store.getters.getFormattedSKUCodes;
			const fullLayersList = this.$store.getters.getFullLayersList;
			
			template = this.$store.state.settings.summary_content;
			template = template.replace('[model]', model);
			Object.keys(layers).forEach(layercode => {
				template = template.replace(`[layers_${layercode}]`, layers[layercode]);
			});
			template = template.replace(/\[layers_[0-9A-Z]+\]/, '');
			template = template.replace('[sku]', sku);
			template = template.replace('[full_layers_list]', fullLayersList);
			template = template.replace('[link]', `<a href="${link}">${link}</a>`);
			template = template.replace('[email]', `<a href="mailto:${email}">${email}</a>`);
		} else {
			template = this.$store.state.settings.set_device_and_pick_patterns_first;
		}
		return template;
	},
	resetToStep(i) {
		if (i < this.step) {
			this.$store.commit('resetCurrent', i);
			this.step = i;
		}
	},
	goToNextStep() {
		this.step += 1;
	}
}
}
</script>

<style lang="scss" scoped>
.apgo-home {
	width: 100%;
	display: block;
	position: relative;

	&.apgo-standalone {
		height: 100%;
	}
}
.apgo-default-mockup-info {
	position: relative;
	z-index: 2;
	padding: 5px 50px 5px 5px;
	background: rgba(255,255,255, .95);
	font-size: .7em;
	top: 0;
	margin-left: 50%;
	transform: translate(-50%, 0);
	box-sizing: border-box;
	width: 50%;
	white-space: pre-line;
	text-align: center;
	border: 1px dashed #FF1C7E;

	> .apgo-close {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 2em;
		display: flex;
		width: 50px;
		height: 100%;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: #000;
		background: #fff;
		opacity: .8;
		animation: all 1s;
		border-left: 1px dashed #FF1C7E;
	}
	> .apgo-close:hover {
		color: #FF1C7E;
		opacity: 1;
	}
}
.apgo-lightbox-inner {
	position: relative;
	width: 80%;
	height: 80%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.apgo-video-box {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	> iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.apgo-lightbox {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 5;
	background: rgba(255, 255, 255, .8);
}
.apgo-order-summary {
	position: relative;
	padding: 25px;
	background: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	white-space: pre-line;
}
.hamburger {
	display: none;
}

.apgo-steps-block {
	width: 100%;
	box-shadow: 0 0 15px rgba(0, 0, 0, .2);
	z-index: 2;
}
.apgo-steps {
	display: flex;
	width: 100%;
}
.apgo-steps > div {
	flex-grow: 1;
	box-sizing: border-box;
}
.apgo-steps .current-selection {
	display: block;
	width: 100%;
	font-size: 12px;
	text-transform: none;
}

.apgo-logo {
	background: white;
	display: flex;
	justify-content: center;
	padding: 25px;

	> img {
		max-height: 150px;
		flex: none;
	}
}
.apgo-button,
.apgo-manual {
	transition: all .5s;
	padding: 25px;
	text-align: left;
	text-transform: uppercase;
	text-decoration: none;
	color: #333;
	background: whitesmoke;
	box-shadow: 0 1px 5px rgba(0, 0, 0, .1) inset;
	font-size: 1em;
	line-height: 1em;
	height: auto;
	display: flex;
	align-items: center;
}
.apgo-button {
	background: #f5f5f5;
	color: #aaa;
	box-shadow: none;
	border-bottom: 1px solid #eee;
	border-right: 1px solid #eee;

	&:last-of-type {
		border-right: none;
	}
}
.apgo-button.chosen {
	color: #840475;
	cursor: pointer;
}
.apgo-button.active {
	background: #FF1793;
	border-right: 1px solid #FF1793;
	border-bottom: 1px solid #FF1793;
	color: white;

	&:last-of-type {
		border-right: none;
	}
}
.apgo-sidebar-image {
	width: 100%;
	display: block;
}
.apgo-nav-icon {
	display: inline-block;
	min-width: 50px;
}
.apgo-submit {
	transition: all .5s;
	padding: 25px;
	text-align: left;
	text-transform: uppercase;
	text-decoration: none;
	box-shadow: 0 1px 5px rgba(0, 0, 0, .1) inset;
	font-size: 1em;
	line-height: 1em;
	color: white;
	cursor: pointer;
	background: linear-gradient(0, #FF8F00, #FFB700);
	display: flex;
	align-items: center;
	box-sizing: border-box;
	animation: blinkBtn 1s ease-in-out infinite;
	transition: all 1s;

	&.absolute {
		display: none;
	}
	&.inactive,
	&.inactive:hover {
		background: whitesmoke;
		color: silver;
		animation: none;
	}
}
.apgo-submit:hover {
	background: linear-gradient(0, #FFB700, #FF8F00);
	animation: none;
}

@media screen and (max-width: 1100px) {
	.hamburger {
		display: flex;
		background: #f5f5f5;
		justify-content: center;
		align-items: center;
		width: 75px;
		height: 75px;
	}
	.hamburger > span {
		display: block;
		position: relative;
		width: 20px;
		height: 20px;
		background: #333;
		box-sizing: border-box;
		border-top: 4px solid #333;
		border-bottom: 4px solid #333;
	}
	.hamburger > span::before,
	.hamburger > span::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 4px;
		background: #f5f5f5;
	}
	.hamburger > span::before { top: 0; }
	.hamburger > span::after { bottom: 0; }
	.hamburger.close > span {
		background: transparent;
		border: none;
	}
	.hamburger.close > span::before,
	.hamburger.close > span::after {
		left: 50%;
		top: 50%;
		bottom: auto;
		width: 100%;
		height: 4px;
		background: #333;
	}
	.hamburger.close > span::before { transform: translate(-50%, -50%) rotate(45deg); }
	.hamburger.close > span::after { transform: translate(-50%, -50%) rotate(135deg); }

	.apgo-home {
		flex-direction: column;
	}
	.apgo-default-mockup-info {
		width: 80%;
		top: 80px;
		margin-left: 50%;
		transform: translate(-50%, 0);
	}
	.apgo-steps-block {
		width: 100%;
		box-shadow: 0 0 15px rgba(0, 0, 0, .2);
		min-height: auto;
		z-index: 2;
	}
	.apgo-button {
		display: none;
		transition: none;
	}
	.apgo-button.active {
		display: block;
		width: calc( 100% - 75px );
	}
	.apgo-button.show {
		display: block;
		width: 100%;
	}
	.apgo-button.show.first-step {
		display: block;
		width: calc( 100% - 75px );
	}
	.apgo-submit  {
		width: 100%
	}
	.apgo-steps {
		flex-wrap: wrap;
	}
	.apgo-steps > div {
		flex: 0 0 auto;
	}
}

@keyframes blinkBtn {
	0% {
		opacity: 1;
	}
	50% {
		opacity: .5;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeInDown {
	from {
		bottom: 100px;
		opacity: 0;
	}
	10% {
		bottom: 70px;
		opacity: 1;
	}
	20% {
		bottom: 70px;
		opacity: 1;
	}
	to {
		bottom: 70px;
		opacity: 0;
	}
}
</style>