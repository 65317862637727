import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
		models: [],
		layers: [],
		patterns: [],
		prices: [],
		categories: [],
		currentModel: null,
		currentPatterns: null,
		currentCodes: null,
		settings: {},
		mockup: null,
		showInfobox: true, // show infobox about default mockup
  },
	actions,
	getters,
	mutations,
})

export default store
